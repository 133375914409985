import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { Button, Header, Input, InputSearch, Footer, FacebookPixels, Notification, SampleArrow, SEO } from 'components'
import inspiredImg1 from 'assets/inspiredImg1.png'
import inspiredImg2 from 'assets/inspiredImg2.png'
import inspiredImg3 from 'assets/inspiredImg3.png'
import inspiredImg4 from 'assets/inspiredImg4.png'
import closePopupIcon from 'assets/closePopupIcon.svg'
import handshake from 'assets/handshake.svg'
import blackLogo from 'assets/fmfLogoBlack.png'
import nextArrow from 'assets/nextArrow.svg'
import prevArrow from 'assets/prevArrow.svg'
import vipClubLogo from 'assets/vipClubLogo.png'
import tshirtImages from 'assets/tshirtImages.jpg'
import facebookIcon from 'assets/facebookIconBlack.svg'
import instagramIcon from 'assets/instagramIconBlack.svg'
import twitterIcon from 'assets/twitterIconBlack.svg'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import { isPasswordValid, isEmailValid } from 'state/LoginPage/actions'
import * as loginActions from 'state/LoginPage/actions'
import { actionCreators as loginActionCreators, selectors as loginSelectors } from 'state/LoginPage/reducer'
import { actionCreators, selectMenu, selectProducts } from 'state/HomePage/reducer'
import * as styles from './HomePage.module.scss'

const introItems = [
  {
    name: 'A new way to buy clothing',
    text: 'Clothing is expression. We are leading the revolution in clothing. Any culture, interest, passion can be worn proudly.'
  },
  {
    name: 'Buy your wardrobe. Wholesale prices.',
    text: 'Unlock the best value in clothing. The future way to shop is here. Clothing at rock bottom pricing. Get an entire wardrobe at wholesale prices.'
  },
  {
    name: 'Fully custom designs',
    text: 'Print anything and everything. Make your ideas and visions into a reality. '
  },
  {
    name: 'Make money with collections',
    text: 'Upload designs and create your own clothing line. Your collections shared to the world. Selling has never been this easy.'
  }
]

const inspiredImages = [inspiredImg1, inspiredImg2, inspiredImg3, inspiredImg4]

if (typeof window !== 'undefind' && typeof document !== 'undefined') {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const HomePage = ({ menu, products, setSearchValue, loginState, setPageAfterLogin, customerAccountRegister }) => {
  const [isShowErrors, setIsShowErrors] = useState(false)
  const [notification, showNotification] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [tooltipClassName, setTooltipClassName] = useState(typeof sessionStorage !== 'undefined' && sessionStorage.getItem('wasPopupShowed') ? styles.tooltipClosed : styles.tooltip)

  const { landing, shared } = useSiteMetadata()

  const instagramLink = shared.INSTAGRAM_LINK
  const facebookLink = shared.FACEBOOK_LINK
  const twitterLink = shared.TWITTER_LINK
  const campaignName = landing.CAMPAIGN.name
  const quickSearchKeys = shared.QUICK_SEARCH_KEYS

  const subscriptionPrices = shared.SUBSCRIPTION_PRODUCT

  useEffect(() => {
    trackingGA()
    sessionStorage.setItem('wasPopupShowed', true)
  }, [])

  useEffect(() => {
    setPageAfterLogin('/')
  }, [setPageAfterLogin])

  useEffect(() => {
    showNotification(loginState.UIState.error)
  }, [loginState.UIState.error])


  const onChangeSearch = debouncedValue => {
    setSearchValue()
    navigate(`/search/${debouncedValue.trim()}`)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsShowErrors(true)
    if (isEmailValid(email).isValid && isPasswordValid(password).isValid && password === repeatPassword) {
      const { payload: isRegistered } = await customerAccountRegister({
        campaignId: landing.CAMPAIGN.id,
        landingId: landing.LANDING_ID,
        email,
        password
      })
      if (isRegistered) {
        showNotification('You are registered successfully!')
        navigate('/vip-signup')
        return true
      }
    }
  }

  const introSettings = {
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleArrow internalClassName={styles.nextSlide} img={nextArrow} />,
    prevArrow: <SampleArrow internalClassName={styles.prevSlide} img={prevArrow} />
  }

  return (
    <div className={styles.homePage}>
      <SEO pathname='/' title={campaignName} />
      <div className={styles.preHeader}>
        <img src={vipClubLogo} alt='VIP CLUB' className={styles.vipClubLogo} />
        <div className={styles.prelogoText}>
          Become a VIP member and shop t-shirts for FREE!{' '}
          <Link to='/#pagejump' className={styles.prelogoTextUnderlined}>
            Details
          </Link>
        </div>
      </div>
      <Header isNeedDescription={!!menu?.selectedCollection} gap={true} />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event='CompleteRegistration' />

      <div className={styles.content}>
        <section className={styles.searchBanner}>
          {loginState.email === '' && (
            <div className={tooltipClassName}>
              <div className={styles.tooltipBtn}>
                <Button value='Login' className='buttonSmall' customAction={() => navigate('/login')} />
              </div>
              <div className={styles.newHere}>
                New here?{' '}
                <Link to='/register' className={styles.signUp}>
                  Sign up
                </Link>
              </div>
              <img className={styles.closePopupIcon} src={closePopupIcon} alt='X' onClick={() => setTooltipClassName(styles.tooltipClosed)} />
            </div>
          )}
          <img className={styles.blackLogo} src={blackLogo} alt='' />
          <h2 className={styles.bannerTitle}>Welcome to Fan Made Fits </h2>
          <h2 className={styles.bannerTitle}>Search anything you love and get free shirts</h2>
          <div className={styles.searchContainer}>
            <InputSearch
              placeholder='Search by keyword'
              onChange={onChangeSearch}
              searchKeys={['productDisplayName', 'collectionName', 'name', 'displayName', 'description']}
              searchSource={products}
              type='primary'
            />
          </div>
          {quickSearchKeys && (
            <div className={styles.quickSearch}>
              <ul className={styles.quickSearchList}>
                {quickSearchKeys.split(',').map((key, indx) => (
                  <li onClick={() => onChangeSearch(key)} className={styles.quickSearchItem} key={`key${indx}`}>
                    {key.trim()}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* <Link to='/' className={styles.viewAllCategories}>
            View all categories
          </Link> */}
        </section>

        <div className={styles.sectionWrapper} id='pagejump'>
          <section className={styles.joinVip}>
            <p className={styles.joinVipSubtitle}>Welcome to the future of clothing</p>
            <p className={styles.joinVipTitle}>Shop Our Top Shirts For Free!</p>
            <p className={styles.joinVipText}>
              Our VIP members get crazy discounts and even free shirts. Join our exclusive community and shop like a pro! Just $
              {(subscriptionPrices.prices[0].memberPrice / 100).toFixed(2)} monthly. Join the VIP membership below.
            </p>

            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.inputContainer}>
                <Input
                  placeholder='Email Address*'
                  onChange={setEmail}
                  isValid={isEmailValid(email).isValid}
                  isShowErrors={isShowErrors}
                  errorMessage={isEmailValid(email).error}
                  name='emailC'
                  autoComplete='email'
                  bigInput
                />
              </div>

              <div className={styles.inputContainer}>
                <Input
                  placeholder='Password*'
                  onChange={setPassword}
                  isValid={isPasswordValid(password).isValid}
                  isShowErrors={isShowErrors}
                  errorMessage={isPasswordValid(password).error}
                  bigInput
                  inputType='password'
                />
              </div>

              <div className={styles.inputContainer}>
                <Input
                  placeholder='Repeat Password*'
                  onChange={setRepeatPassword}
                  isValid={repeatPassword === password ? true : false}
                  isShowErrors={isShowErrors}
                  errorMessage='Password does not match'
                  bigInput
                  inputType='password'
                />
              </div>
              <p className={styles.passwordRules}>Password must contain at least 6 symbols: one upper letter, one number, and one special character.</p>
              <div className={styles.buttonContainer}>
                <Button value='Join VIP Club >' loading={loginState.UIState.isLoading} />
              </div>
            </form>
            <Link className={styles.alreadyMember} to='/login'>
              Already a member? Login
            </Link>
          </section>

          <section className={styles.gallery}>
            <img src={tshirtImages} alt='' />
          </section>
        </div>

        <section className={styles.about}>
          <h2 className={styles.aboutTitle}>Why Fan made fits?</h2>
          <img src={handshake} alt='' className={styles.aboutIcon} />

          <div className={styles.whyContainer}>
            <h3 className={styles.whyTitle}>Trusted and satisfied over 130,000 customers since 2019</h3>
            <p className={styles.whyInfo}>
              Fan made fits have come a long journey to build trust with our customers with many testing to produce the best quality products and to give the best experience
              and service. We still continue to test in many areas to improve. Thank you for showing us love!
            </p>
          </div>
        </section>

        <section className={styles.intro}>
          <h2 className={styles.introTitle}>Welcome to the Future of Clothing</h2>
          <ul className={styles.introList}>
            <Slider {...introSettings}>
              {introItems.map(({ name, text }) => (
                <li className={styles.introItem} key={name}>
                  <div>
                    <h3 className={styles.introItemName}>{name}</h3>
                    <p className={styles.introItemText}>{text}</p>
                  </div>
                  <Link to='/membership' className={styles.introItemBtn}>
                    Learn More
                  </Link>
                </li>
              ))}
            </Slider>
          </ul>
        </section>

        <section className={styles.customers}>
          <div className={styles.customersGallery}>
            {inspiredImages.map(img => (
              <div className={styles.customersGalleryItem} key={img}>
                <img src={img} alt='' />
              </div>
            ))}
          </div>
        </section>

        <section className={styles.subscribeSection}>
          <p className={styles.subscribeTitle}>Let's Connect!</p>
          <p className={styles.subscribeText}>Keep up with the latest news, and get inside scoops on new releases and promos. Gollow us on social media to stay connected.</p>
          <div className={styles.socialBox}>
            {facebookLink && (
              <a href={facebookLink} target='blank' className={styles.socialItem}>
                <img className={styles.socialIcon} src={facebookIcon} alt='' />
              </a>
            )}
            {instagramLink && (
              <a href={instagramLink} target='blank' className={styles.socialItem}>
                <img className={styles.socialIcon} src={instagramIcon} alt='' />
              </a>
            )}
            {twitterLink && (
              <a href={twitterLink} target='blank' className={styles.socialItem}>
                <img className={styles.socialIcon} src={twitterIcon} alt='' />
              </a>
            )}
          </div>
          {/* <p className={styles.subscribeText}>Subscribe to our newsletter to receive discounts!</p>
          <div className={styles.inputContainer}>
            <InputFooter placeholder='Enter your email address' />
          </div>
          <div className={styles.button}>
            <Button value='Subscribe' className='buttonMedium' />
          </div> */}
        </section>
      </div>

      <Notification message={notification} />
      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  menu: selectMenu(state),
  products: selectProducts(state),
  loginState: loginSelectors.selectLoginState(state)
})

const mapDispatchToProps = {
  customerAccountRegister: loginActions.customerAccountRegister,

  resetMenuFilters: actionCreators.resetMenuFilters,
  setSearchValue: actionCreators.setSearchValue,
  setMenuSubcollection: actionCreators.setMenuSubcollection,
  setPageAfterLogin: loginActionCreators.setPageAfterLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
